body {
    --custom-dark: #000000;
    /*--custom-dark: #09080c; (mine)*/
    /*--custom-dark: #1a1b1e; (oldest)*/
    --custom-light: #ffffff;
    /*--custom-light: #f9f8fc;*/

    --mantine-color-body: var(--custom-light);
}

    [data-mantine-color-scheme='dark'] body {

    --mantine-color-body: var(--custom-dark);
}

    body {
    background-color: var(--mantine-color-body);
}

.border-animated {
    outline-width: 1px;
    outline-offset: 0;
    outline-color: rgba(224, 55, 90, 1);
    outline-style: solid;
    animation: animateOutline 2.5s ease infinite;
}

@keyframes animateOutline {
    0% {
        outline-width: 1px;
        outline-offset: 0;
        outline-color: rgba(224, 55, 90, 0);
    }

    25% {
        outline-color: rgba(198, 27, 64, 1);
    }
        [data-mantine-color-scheme='dark'] 25% {
        outline-color: rgba(255, 233, 239, 1);
    }

    50% {
        outline-width: 1px;
        outline-offset: 3px;
        outline-color: rgba(224, 55, 90, 1);
    }

    100% {
        outline-width: 5px;
        outline-offset: 5px;
        outline-color: rgba(198, 27, 64, 0);
    }
        [data-mantine-color-scheme='dark'] 100% {
        outline-color: rgba(255, 233, 239, 0);
    }
}

